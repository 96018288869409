body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

.header {
  padding: 0 36px;
  padding-inline: 24px;
  background: white !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
    0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  margin-bottom: 10px;
}

.header-logo {
  float: left;
  font-size: 24px !important;
  font-weight: bold;
  padding-right: 24px;
  cursor: default;
  white-space: nowrap;
}

@keyframes button-expand {
  0% {
    width: 20%;
  }
  25% {
    width: 40%;
  }
  50% {
    width: 60%;
  }
  75% {
    width: 80%;
  }
  100% {
    width: auto;
  }
}

.header__user-button {
  width: 50px;
  max-width: 50px;
}

.header__user-button__hover {
  max-width: 500px;
  transition: max-width 0.5s;
}

.checkbox-list .ant-checkbox-wrapper {
  padding: 8px 0;
  width: 100%;
}

.checkbox-list .ant-list-item {
  padding: 0 0;
}

.checkbox-list .ant-checkbox-wrapper-checked:not(:hover) {
  background-color: #f6ffed;
}

.checkbox-list .ant-checkbox-wrapper-checked:hover {
  background-color: #d9f7be;
  transition: 0.5s;
}

.checkbox-list .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-checked):hover {
  background-color: #fafafa;
}
